<template>
  <div class="w-full">
    <div class="w-full">
      <Card class="p-5">
        <div class="flex mb-8 mt-2" style="justify-content: flex-end"></div>
        <template v-slot:footer>
          <card-footer
            reloadcard
            showcalendar
            @searchResult="handleSearch($event)"
            @reload="reload()"
          />
        </template>
      </Card>
    </div>

    <div class="w-full h-full p-3" />

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <Table
        :headers="PerfHeader"
        :items="orgAgreements"
        v-if="orgAgreements.length > 0 || loadingTable"
        aria-label="advance Table"
        class="w-full"
        :has-checkbox="false"
        :has-number="false"
        :loading="loadingTable"
        :pagination-list="metaData"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
      >
        <template v-slot:item="{ item }">
          <div v-if="item.periodStart" class="py-4">
            {{ item.data.periodStart }}
          </div>
          <div v-else-if="item.kpaTypes">
            <div class="flex flex-wrap">
              <div v-for="(kpas, index) in item.data.kpaTypes" :key="index">
                <Badge
                  :label="kpas.name"
                  variant="primary"
                  background-color="rgba(135, 142, 153, 0.15)"
                  color="#333333"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    height: 35px;
                    border-radius: 5px;
                  "
                />
              </div>
            </div>
          </div>
          <span v-else-if="item.status">
            <Badge
              v-if="item.data.status === 'active'"
              label="Active"
              variant="primary"
              background-color="rgba(19, 181, 106, 0.08)"
              color="#13B56A"
              style="padding: 9px; height: 33px; font-weight: 600"
            />
            <Badge
              v-if="item.data.status === 'draft'"
              label="Draft"
              variant="primary"
              background-color="rgba(135, 142, 153, 0.15)"
              color="#333333"
              style="padding: 9px; height: 33px; font-weight: 600"
            />
            <Badge
              v-if="
                item.data.status !== 'active' && item.data.status !== 'draft'
              "
              label="Closed"
              variant="primary"
              background-color="rgba(135, 142, 153, 0.15)"
              color="#878E99"
              style="padding: 9px; height: 33px; font-weight: 600"
            />
          </span>
          <span v-else-if="item.id">
            <div
              class="flex py-1 more h-8 cursor-pointer"
              @click="
                $router.push({
                  name: 'OrganisationlgoalView',
                  params: { id: item.data.id },
                })
              "
            >
              <icon
                icon-name="icon-eye"
                class-name="text-blueCrayola mr-2"
                size="xs"
              />
            </div>
          </span>
        </template>
      </Table>

      <div v-else>
        <div class="w-full flex flex-col justify-center items-center mt-10">
          <icon icon-name="goals-empty" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No organizational goals added
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Card,
    CardFooter,
    Badge,
  },

  data() {
    return {
      PerfHeader: [
        { title: "Financial Year", value: "year" },
        { title: "Description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      modalDel: false,
      shortTermGoals: [],
      loading: true,
      dialogDel: false,
      orgAgreements: [],
      reportingTo: false,
      appraisalsArr: [],
      templateArr: [],
      createAgreement: false,
      loadingTable: false,
      manager: false,
      goalId: "",
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getGoalsAgreements(`?filter=agreement${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getGoalsAgreements(`?filter=agreement${this.searchQuery}`);
    },

    reload() {
      this.getGoalsAgreements('?filter=agreement');
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        if (appraisal.length > 0) {
          const apprArray = [];

          appraisal.forEach((appraisalCycles) => {
            const startDate = `${appraisalCycles.cycle_start_date}`;

            const endDate = `${appraisalCycles.cycle_end_date}`;

            apprArray.push({
              periodStart: `${this.formatDateTime(
                startDate
              )} - ${this.formatDateTime(endDate)}`,
            });

            appraisalCycles.cycles.forEach((cycle) => {
              this.PerfHeader.push({
                title: cycle.name,
                value: cycle.name,
              });

              apprArray.push({
                [cycle.name]: `${this.formatDateTime(
                  cycle.appraisal_starts
                )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
              });
            });

            this.appraisalsArr.push(
              apprArray.reduce((r, i) => Object.assign(r, i), {})
            );

            this.PerfHeader.push(
              { title: "Status", value: "status" },
              { title: "", value: "id", image: true }
            );
          });

          this.getGoalsAgreements('?filter=agreement');
        } else {
          this.loading = false;
        }
      });
    },

    getGoalsAgreements(params) {
      this.loadingTable = true;
      this.orgAgreements = [];

      this.$_getOrganisationGoal(params)
        .then((response) => {
          const goalData = response.data.goals;
          const arr = [];

          if (goalData.length > 0) {
            goalData.forEach((goals) => {
              arr.push({
                id: goals.goal.id,
                year: goals.goal.year,
                description: goals.goal.description,
                status: goals.goal.status,
              });
            });

            arr.push(
              this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
            );

            this.orgAgreements.push(
              arr.reduce((r, c) => Object.assign(r, c), {})
            );

            this.metaData = response.data.meta;
          }
          this.loading = false;
          this.loadingTable = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getAppraisalCycle();
  },
};
</script>
