<template>
  <div class="w-full">
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div v-if="agreementOpenDate !== ''">
        <div class="w-full">
          <Card class="p-5">
            <div class="w-full flex">
              <div class="flex flex-grow mb-6"></div>
              <div
                class="flex mb-8 mt-2"
                style="justify-content: flex-end"
                v-if="createAgreement && startAgreement"
              >
                <Button
                  class="bg-dynamicBackBtn text-white"
                  @click="$router.push({ name: 'CreateSharedAgreement' })"
                  v-if="createAgreement"
                >
                  Create Agreement
                </Button>
              </div>

              <div class="w-auto" v-else>
                <div
                  class="w-full flex mx-4 p-2 mb-2 rounded-md bg-yellow-50 border border-yellow-500"
                  v-if="!loading && endAgreement"
                >
                  <Icon
                    class-name="text-carrotOrange mx-auto self-center"
                    size="xs"
                    icon-name="icon-info"
                  />
                  <span class="flex text-sm mx-3" v-if="endAgreement">
                    Agreement contracting period ended on
                    {{
                      $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy")
                    }}
                  </span>
                </div>

                <div
                  class="w-full flex mx-4 p-2 mb-2 rounded-md bg-yellow-50 border border-yellow-500"
                  v-if="!loading && !endAgreement && !startAgreement"
                >
                  <span
                    class="flex text-sm mx-3"
                    v-if="!endAgreement && !startAgreement"
                  >
                    Agreement contracting period starts on
                    {{
                      $DATEFORMAT(new Date(agreementOpenDate), "MMMM dd, yyyy")
                    }}
                    and ends on
                    {{
                      $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy")
                    }}
                  </span>
                </div>
              </div>
            </div>

            <template v-slot:footer>
              <card-footer
                reloadcard
                showcalendar
                @searchResult="handleSearch($event)"
                @reload="reload()"
              />
            </template>
          </Card>
        </div>

        <div>
          <Table
            :headers="PerfHeader"
            :items="sharedAgreements"
            v-if="sharedAgreements.length > 0 || loadingTable"
            aria-label="advance Table"
            class="w-full"
            :has-checkbox="false"
            has-number
            :loading="loadingTable"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div
                v-if="item.year"
                class="font-semibold text-sm leading-5 tracking-widest"
              >
                <span class="text-darkPurple">
                  {{ item.data.year }}
                </span>
              </div>
              <div v-else-if="item.periodStart" class="flex flex-wrap">
                <span class="flex text-sm">
                  {{ item.data.periodStart }}
                </span>
              </div>
              <div
                v-else-if="item.employees"
                class="font-semibold text-sm leading-5 tracking-widest whitespace-nowrap"
              >
                <span class="text-darkPurple"> {{ item.data.employees }}</span>
              </div>
              <div v-else-if="item.status">
                <Badge
                  :label="item.data.status"
                  variant="primary"
                  :background-color="
                    item.data.status === 'active'
                      ? `rgba(19, 181, 106, 0.15)`
                      : `rgba(135, 142, 153, 0.15)`
                  "
                  :color="item.data.status === 'active' ? '#13B56A' : '#333333'"
                  class="capitalize"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    height: 35px;
                    border-radius: 5px;
                  "
                />
              </div>
              <div v-else-if="item.id">
                <Menu left top="-130" class="p-0" margin="26">
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div style="width: 180px; min-height: 1px" class="py-1">
                    <div
                      class="flex pb-3 px-3 mt-2 more h-8 cursor-pointer"
                      v-if="item.data.status === 'draft'"
                      @click="routeDraftAgreement(item.data.id, item.data.year)"
                    >
                      <icon
                        icon-name="file-document"
                        size="xsm"
                        class="-mt-1 -ml-2"
                        style="padding: 3px 10px; width: 33px; height: 33px"
                      />
                      <p class="pt-1" style="font-size: 14px">Update</p>
                    </div>

                    <div
                      class="flex pb-3 mb-1 mt-2 px-3 more h-8 cursor-pointer"
                      v-if="item.data.status === 'active'"
                      @click="
                        $router.push({
                          name: 'DirectReportsDetails',
                          params: { year: item.data.year },
                        })
                      "
                    >
                      <icon
                        icon-name="icon-eye"
                        size="xsm"
                        class="-mt-1 -ml-2 text-blueCrayola"
                        style="padding: 3px 10px; width: 36px; height: 36px"
                      />
                      <p class="pt-1" style="font-size: 14px">
                        View Agreements
                      </p>
                    </div>

                    <div
                      class="flex px-3 more cursor-pointer"
                      v-if="item.data.status === 'draft'"
                      @click="modalDelete(item)"
                    >
                      <icon
                        icon-name="icon-trash"
                        size="xsm"
                        class="-mt-1 -ml-2"
                        style="padding: 3px 10px; width: 33px; height: 33px"
                      />
                      <p class="pt-1" style="font-size: 14px">Delete</p>
                    </div>
                  </div>
                </Menu>
              </div>
            </template>
          </Table>

          <div class="w-full" v-else>
            <div class="w-full flex flex-col justify-center items-center mt-20">
              <icon icon-name="empty_agreement" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                No performance agreements displayed. Added agreements will be
                displayed once they are added.
              </div>
            </div>
          </div>
        </div>

        <Modal v-if="modalDel">
          <Card class="p-5 flex flex-col max-w-md">
            <div class>
              <p class="font-bold ml-4 my-5 text-center">
                Are you sure you want to delete this agreement?
              </p>
              <div class="flex">
                <Button class="bg-dynamicBackBtn text-white" @click="deleteAgreement">
                  Yes
                </Button>
                <Button
                  background-color="none"
                  class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                  @click="modalDel = false"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
      </div>

      <div v-else>
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No performance agreements displayed. Organization Goals has not been
            created by Admin
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Card,
    CardFooter,
    Menu,
    Modal,
    Badge,
    Button,
  },
  data() {
    return {
      PerfHeader: [
        { title: "Financial Year", value: "year" },
        { title: "Description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      modalDel: false,
      shortTermGoals: [],
      loading: true,
      dialogDel: false,
      startAgreement: false,
      endAgreement: false,
      sharedAgreements: [],
      loadingTable: true,
      reportingTo: false,
      appraisalsArr: [],
      templateArr: [],
      createAgreement: false,
      manager: false,
      agreementId: [],
      agreementOpenDate: "",
      agreementCloseDate: "",
      idLength: "",
      goalId: "",
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getAppraisalCycle(
        `${this.agreementQuery()}${pageNumber}${itemPage}${search}`
      );
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getAppraisalCycle(`${this.agreementQuery()}${this.searchQuery}`);
    },

    reload() {
      this.loadingTable = true;
      this.getAgreements();
    },

    routeDraftAgreement(agreementData, year) {
      this.$router.push({
        name: "draftAgreementDetails",
        params: { agreement: agreementData[0].agreement.id, year },
      });
    },

    modalDelete(agreementId) {
      agreementId.data.id.map((dataObj) => {
        this.agreementId.push(dataObj.agreement.id);
        return {};
      });

      this.idLength = agreementId.data.id.length;
      this.modalDel = true;
    },

    deleteAgreement() {
      this.agreementId.map((id, index) => {
        this.$_deleteAgreement(id)
          .then((response) => {
            if (index + 1 === this.idLength) {
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.modalDel = false;
              this.sharedAgreements = [];
              this.getAgreements();
            }
          })
          .catch((err) => {
            this.$toasted.err(err.response.data.message, { duration: 5000 });
          });
        return {};
      });
    },

    cycleName(templateId) {
      let name;

      this.templateArr.filter((template) => {
        if (template.id === templateId) {
          name = template.agreement_title;
        }
        return {};
      });

      return name || "";
    },

    getAgreements() {
      this.sharedAgreements = [];

      this.$_getDirectReportAgreements(this.$AuthUser.id).then((response) => {
        this.loading = false;

        const agreements = response.data;
        const activeAgreement = [];
        const draftAgreement = [];
        const closedAgreement = [];

        if (agreements.activeAgreement.length > 0) {
          activeAgreement.push({
            employees: agreements.activeAgreement.length,
            status: "active",
            id: agreements.activeAgreement[0].agreement.id,
          });

          activeAgreement.push(
            this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
          );

          activeAgreement.push(
            this.shortTermGoals.reduce((r, i) => Object.assign(r, i), {})
          );

          this.sharedAgreements.push(
            activeAgreement.reduce((r, i) => Object.assign(r, i), {})
          );
        }

        if (agreements.draftAgreement.length > 0) {
          const ownerIds = [];
          agreements.draftAgreement.map((draft) => {
            ownerIds.push(draft.owner.userId);
            return {};
          });

          draftAgreement.push({
            employees: agreements.draftAgreement.length,
            status: "draft",
            id: agreements.draftAgreement,
            employeesIds: ownerIds,
          });

          draftAgreement.push(
            this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
          );

          draftAgreement.push(
            this.shortTermGoals.reduce((r, i) => Object.assign(r, i), {})
          );

          this.sharedAgreements.push(
            draftAgreement.reduce((r, i) => Object.assign(r, i), {})
          );
        }

        if (agreements.closedAgreement.length > 0) {
          closedAgreement.push({
            employees: agreements.closedAgreement.length,
            status: "closed",
            id: agreements.closedAgreement[0].agreement.id,
          });

          closedAgreement.push(
            this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
          );

          closedAgreement.push(
            this.shortTermGoals.reduce((r, i) => Object.assign(r, i), {})
          );

          this.sharedAgreements.push(
            closedAgreement.reduce((r, i) => Object.assign(r, i), {})
          );
        }

        this.loadingTable = false;
      });
    },

    getOrganizationGoal() {
      this.shortTermGoals = [];
      this.$_getOrganizationGoal(this.searchQuery).then((response) => {
        const goalData = response.data.goals;

        if (goalData.length > 0) {
          goalData.forEach((goals) => {
            this.shortTermGoals.push({
              year: goals.goal.year,
              description:
                goals.goal.goal_performance_templates.agreement_description,
            });

            return {};
          });
        }

        this.getAgreements();
      });
    },

    getAppraisalCycle(params) {
      this.$_getAppraisalCycle(params).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        this.metaData = response.data.meta;

        if (appraisal.length > 0) {
          const extension = response.data.extension
            ? response.data.extension.extendTo
            : "";

          const agreementStartDate = this.setDates(
            appraisal[0].agreement_start
          );
          const agreementEndDate = this.setDates(appraisal[0].agreement_end);

          agreementEndDate.setDate(agreementEndDate.getDate() + 1);

          this.agreementOpenDate = agreementStartDate.toISOString();
          this.agreementCloseDate = this.setDates(
            appraisal[0].agreement_end
          ).toISOString();

          const today = new Date();

          if (today >= agreementStartDate && today <= agreementEndDate) {
            this.startAgreement = true;
            this.endAgreement = false;
          }

          if (today > agreementStartDate && today > agreementEndDate) {
            if (today > extension) {
              this.startAgreement = false;
              this.endAgreement = true;
            } else {
              this.startAgreement = true;
              this.endAgreement = false;
            }
          }

          if (today < agreementStartDate && today < agreementEndDate) {
            this.startAgreement = false;
            this.endAgreement = false;
          }

          const apprArray = [];

          const hasProperty = this.PerfHeader.some(
              (header) => header.title === "Status"
          );

          appraisal.map((appraisalCycles) => {
            const startDate = `${appraisalCycles.cycle_start_date}`;

            const endDate = `${appraisalCycles.cycle_end_date}`;

            apprArray.push({
              periodStart: `${this.formatDateTime(
                startDate
              )} - ${this.formatDateTime(endDate)}`,
            });

            appraisalCycles.cycles.map((cycle) => {
              if (!hasProperty) {
              this.PerfHeader.push({
                title: cycle.name,
                value: cycle.name,
              });

              apprArray.push({
                [cycle.name]: `${this.formatDateTime(
                  cycle.appraisal_starts
                )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
              });
              }
              return {};
            });

            if (!hasProperty) {
            this.appraisalsArr.push(
              apprArray.reduce((r, i) => Object.assign(r, i), {})
            );

            this.PerfHeader.push(
              { title: "Employees", value: "employees" },
              { title: "Status", value: "status" },
              { title: "", value: "id", image: true }
            );
            }

            return {};
          });

          this.getOrganizationGoal();
        } else {
          this.loading = false;
        }
      });
    },

    getPerformanceSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        response.data.PerformanceSettings.map((setting) => {
          const agreementEdit = setting.editDocument;
          this.getUser(agreementEdit);
          return {};
        });
      });
    },

    getUserReportingTo() {
      const payload = { id: this.$AuthUser.id };

      this.$_getOneEmployeeAll(payload).then((response) => {
        const reportingTo = response.data.userReportingTo;

        if (reportingTo === null) {
          this.reportingTo = false;
        } else {
          this.reportingTo = true;
        }
      });
    },

    getUser(agreementEdit) {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((result) => {
        this.loadingData = false;
        if (result.data.directReport.length === 0) {
          this.manager = false;
        } else {
          this.manager = true;
        }

        if (agreementEdit === "manager" && this.manager) {
          this.createAgreement = true;
        } else if (agreementEdit === "manager" && !this.manager) {
          this.createAgreement = false;
        } else if (
          agreementEdit === "employee" &&
          this.manager &&
          !this.reportingTo
        ) {
          this.createAgreement = false;
        } else if (
          agreementEdit === "employee" &&
          this.manager &&
          this.reportingTo
        ) {
          this.createAgreement = true;
        } else if (agreementEdit === "employee" && !this.manager) {
          this.createAgreement = true;
        } else if (agreementEdit === "both") {
          this.createAgreement = true;
        }
      });
    },
  },

  mounted() {
    this.getAppraisalCycle(this.agreementQuery());
    this.getPerformanceSettings();
    this.getUserReportingTo();
    window.localStorage.removeItem("checkedEmployees");
  },
};
</script>
