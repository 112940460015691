<template>
  <div class="w-full">
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div v-if="agreementOpenDate !== ''">
        <div class="w-full">
          <Card class="p-5">
            <div class="w-full flex">
              <div class="flex flex-grow mb-6"></div>
              <div
                class="flex mb-8 mt-2"
                style="justify-content: flex-end"
                v-if="createAgreement && startAgreement"
              >
                <Button
                  class="bg-dynamicBackBtn text-white"
                  @click="
                    !existingAgreement
                      ? $router.push({ name: 'CreateAgreement' })
                      : errorCreation()
                  "
                >
                  Create Agreement
                </Button>
              </div>

              <div class="w-auto">
                <div
                  class="w-full flex mx-4 p-2 mb-2 rounded-md bg-yellow-50 border border-yellow-500"
                  v-if="endAgreement"
                >
                  <Icon
                    class-name="text-carrotOrange mx-auto self-center"
                    size="xs"
                    icon-name="icon-info"
                  />
                  <span class="flex text-sm mx-3">
                    Agreement contracting period ended on
                    {{
                      $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy")
                    }}
                  </span>
                </div>

                <div
                  class="w-full flex mx-4 p-2 mb-2 rounded-md bg-yellow-50 border border-yellow-500"
                  v-if="!endAgreement && !startAgreement"
                >
                  <span
                    class="flex text-sm mx-3"
                    v-if="!endAgreement && !startAgreement"
                  >
                    Agreement contracting period starts on
                    {{
                      $DATEFORMAT(new Date(agreementOpenDate), "MMMM dd, yyyy")
                    }}
                    and ends on
                    {{
                      $DATEFORMAT(new Date(agreementCloseDate), "MMMM dd, yyyy")
                    }}
                  </span>
                </div>

                <!----<div
            class="w-5/12 flex mx-4 p-2 rounded-md bg-yellow-50 border border-yellow-500"
            v-if="!startAgreement"
          >
            <Icon
              class-name="text-carrotOrange mx-auto self-center"
              size="xs"
              icon-name="icon-info"
            />
            <span class="flex text-sm mx-3">
              Agreement starts on
              {{ $DATEFORMAT(new Date(appraisalStartDate), "MMMM dd, yyyy") }}.
              You will be notified when it begins.
            </span>
          </div>-->
              </div>
            </div>

            <template v-slot:footer>
              <card-footer
                reloadcard
                showcalendar
                @searchResult="handleSearch($event)"
                @reload="reload()"
              />
            </template>
          </Card>
        </div>

        <div>
          <Table
            :headers="PerfHeader"
            :items="myAgreements"
            v-if="myAgreements.length > 0 || loadingTable"
            aria-label="advance Table"
            class="w-full"
            :has-checkbox="false"
            :has-number="false"
            :loading="loadingTable"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div v-if="item.periodStart" class="flex flex-wrap">
                <span class="flex text-sm">
                  {{ formatDateTime(item.data.periodStart) }}
                </span>
                <span class="flex text-sm mx-1"> - </span>
                <span class="flex text-sm">
                  {{ formatDateTime(item.data.periodEnd) }}
                </span>
              </div>
              <div class="flex items-center" v-else-if="item.createdBy">
                <div class="flex flex-grow">
                  <div
                    style="height: 30px; width: 30px; border-radius: 5px"
                    class="mr-3"
                    v-if="item.data.createdBy.photo"
                  >
                    <img
                      class="mr-3"
                      :src="item.data.createdBy.photo"
                      alt="user photo"
                      style="height: 30px; width: 30px"
                    />
                  </div>
                  <div
                    style="height: 30px; width: 30px; border-radius: 5px"
                    class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                    v-else
                  >
                    {{
                      $getInitials(
                        `${item.data.createdBy.fname} ${item.data.createdBy.lname}`
                      )
                    }}
                  </div>
                </div>
                <div class="flex flex-col flex-grow">
                  <p>
                    {{
                      `${item.data.createdBy.fname} ${item.data.createdBy.lname}`
                    }}
                  </p>
                </div>
              </div>
              <div class="flex items-center" v-else-if="item.createdFor">
                <div class="flex flex-grow">
                  <div
                    style="height: 30px; width: 30px; border-radius: 5px"
                    class="mr-3"
                    v-if="item.data.createdFor.photo"
                  >
                    <img
                      class="mr-3"
                      :src="item.data.createdFor.photo"
                      alt="user photo"
                      style="height: 30px; width: 30px"
                    />
                  </div>
                  <div
                    style="height: 30px; width: 30px; border-radius: 5px"
                    class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                    v-else
                  >
                    {{
                      $getInitials(
                        `${item.data.createdFor.fname} ${item.data.createdFor.lname}`
                      )
                    }}
                  </div>
                </div>
                <div class="flex flex-col flex-grow">
                  <p>
                    {{
                      `${item.data.createdFor.fname} ${item.data.createdFor.lname}`
                    }}
                  </p>
                </div>
              </div>
              <div v-else-if="item.status">
                <Badge
                  :label="item.data.status"
                  variant="primary"
                  :background-color="
                    item.data.status === 'active'
                      ? `rgba(19, 181, 106, 0.15)`
                      : `rgba(135, 142, 153, 0.15)`
                  "
                  :color="item.data.status === 'active' ? '#13B56A' : '#333333'"
                  class="capitalize"
                  style="
                    margin-right: 12px;
                    font-size: 14px;
                    padding: 12px 10px;
                    height: 35px;
                    border-radius: 5px;
                  "
                />
              </div>
              <div v-else-if="item.id">
                <Menu left top="-150" margin="24" class="my-2">
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div style="width: 150px; padding: 10px 15px">
                    <div
                      class="cursor-pointer flex"
                      @click="
                        $router.push({
                          name: 'AgreementDetails',
                          params: {
                            id: `${item.data.id}`,
                            cycle: `${item.data.description}`,
                            year: `${item.data.year}`,
                          },
                        })
                      "
                    >
                      <icon
                        icon-name="icon-eye"
                        size="xsm"
                        class="text-blueCrayola"
                      />
                      <p class="text-sm ml-2">View</p>
                    </div>

                    <div
                      class="flex mt-2 cursor-pointer"
                      @click="modalDelete(item.data.id)"
                      v-if="item.data.status === 'draft'"
                    >
                      <icon icon-name="delete" size="xsm" class="text-desire" />
                      <p class="text-sm ml-2 pt-1">Delete</p>
                    </div>
                  </div>
                </Menu>
              </div>
            </template>
          </Table>

          <div class="w-full" v-else>
            <div class="w-full flex flex-col justify-center items-center mt-20">
              <icon icon-name="empty_agreement" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                No performance agreements displayed. Added agreements will be
                displayed once they are added.
              </div>
            </div>
          </div>
        </div>

        <Modal v-if="modalDel">
          <Card class="p-5 flex flex-col max-w-md">
            <div class>
              <p class="font-bold ml-4 my-5 text-center">
                Are you sure you want to delete this agreement?
              </p>
              <div class="flex">
                <Button class="bg-dynamicBackBtn text-white" @click="deleteAgreement">
                  Yes
                </Button>
                <Button
                  background-color="none"
                  class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                  @click="modalDel = false"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </Modal>
      </div>

      <div v-else>
        <div class="w-full flex flex-col justify-center items-center mt-20">
          <icon icon-name="empty_agreement" size="l" style="width: 300px" />
          <div class="w-1/2 text-base text-center">
            No performance agreements displayed. Organization Goals has not been
            created by Admin
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Card,
    CardFooter,
    Menu,
    Button,
    Badge,
    Modal,
  },
  data() {
    return {
      PerfHeader: [
        { title: "Financial Year", value: "year" },
        { title: "Description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      myAgreements: [],
      loadingTable: true,
      orgGoals: [],
      existingAgreement: false,
      templateArr: [],
      appraisalsArr: [],
      loading: true,
      reportingTo: false,
      dialogDel: false,
      startAgreement: false,
      endAgreement: false,
      agreementOpenDate: "",
      agreementCloseDate: "",
      aggreementCycle: "",
      goalId: "",
      managerId: "",
      hasSharedAgreement: false,
      sharedAgreementId: "",
      modalDel: false,
      agreementId: "",
      createAgreement: false,
      manager: false,
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getAppraisalCycle(
        `${this.agreementQuery()}${pageNumber}${itemPage}${search}`
      );
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.getAppraisalCycle(`${this.agreementQuery()}${this.searchQuery}`);
    },

    reload() {
      this.loadingTable = true;
      this.getAppraisalCycle(this.agreementQuery());
    },

    errorCreation() {
      if (this.orgGoals.length === 0) {
        this.$toasted.error("Organization goals not set up", {
          duration: 5000,
        });
      } else {
        this.$toasted.error("You have an agreement in process for this year", {
          duration: 5000,
        });
      }
    },

    modalDelete(agreementId) {
      this.agreementId = agreementId;
      this.modalDel = true;
    },

    deleteAgreement() {
      this.$_deleteAgreement(this.agreementId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.modalDel = false;
          this.myAgreements = [];
          this.existingAgreement = false;
          this.getAgreements();
        })
        .catch((err) => {
          this.$toasted.err(err.response.data.message, { duration: 5000 });
        });
    },

    cycleName(templateId) {
      let name;

      this.templateArr.filter((template) => {
        if (template.id === templateId) {
          name = template.agreement_title;
        }
        return {};
      });

      return name || "";
    },

    getAgreements() {
      this.loadingTable = true;
      this.myAgreements = [];

      this.$_getEmployeeAgreement(this.$AuthUser.id).then((response) => {
        this.loading = false;

        const myAgreements = response.data.EmployeePerformanceAgreements;

        const currentYear = new Date().getFullYear();

        const agreementArr = [];

        if (myAgreements.length > 0) {
          myAgreements.forEach((agreement) => {
            if (
              agreement.created_for !== agreement.created_by &&
              !agreement.approveAndSubmit
            ) {
              this.hasSharedAgreement = true;
              this.sharedAgreementId = agreement.id;
              this.managerId = agreement.created_by;
              this.aggreementCycle = this.cycleName(agreement.templateId);
            }

            if (Number(agreement.year) === currentYear) {
              this.existingAgreement = true;
            }

            agreementArr.push({
              year: agreement.year,
              description: agreement.description,
              status: agreement.status,
              id: agreement.id,
              manager: agreement.created_by,
            });

            agreementArr.push(
              this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
            );

            this.myAgreements.push(
              agreementArr.reduce((r, c) => Object.assign(r, c), {})
            );

            this.loading = false;
          });
        }
        this.loadingTable = false;
      });
    },

    getSharedByDetails(createdBy, employeeDetails) {
      const objData = {};

      if (createdBy === this.$AuthUser.id) {
        objData.photo = employeeDetails.photo;
        objData.fname = employeeDetails.fname;
        objData.lname = employeeDetails.lname;
      } else {
        objData.photo = employeeDetails.lineManager.photo;
        objData.fname = employeeDetails.lineManager.fname;
        objData.lname = employeeDetails.lineManager.lname;
      }

      return objData;
    },

    getSharedToDetails(createdFor, employeeDetails) {
      const objData = {};

      if (createdFor === this.$AuthUser.id) {
        objData.photo = employeeDetails.photo;
        objData.fname = employeeDetails.fname;
        objData.lname = employeeDetails.lname;
      } else {
        objData.photo = employeeDetails.lineManager.photo;
        objData.fname = employeeDetails.lineManager.fname;
        objData.lname = employeeDetails.lineManager.lname;
      }

      return objData;
    },

    getTemplate() {
      this.$_getPerfomanceTemplate("").then((response) => {
        const templatesArr = response.data.PerformanceTemplate;

        this.templateArr = templatesArr;

        if (this.templateArr.length > 0) {
          this.getAppraisalCycle(this.agreementQuery());
        } else {
          this.loading = false;
        }
      });
    },

    getAppraisalCycle(params) {
      this.$_getAppraisalCycle(params).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        this.metaData = response.data.meta;

        if (appraisal.length > 0) {
          const extension = response.data.extension
            ? response.data.extension.extendTo
            : "";

          const agreementStartDate = this.setDates(
            appraisal[0].agreement_start
          );
          const agreementEndDate = this.setDates(appraisal[0].agreement_end);

          agreementEndDate.setDate(agreementEndDate.getDate() + 1);

          this.agreementOpenDate = agreementStartDate.toISOString();
          this.agreementCloseDate = this.setDates(
            appraisal[0].agreement_end
          ).toISOString();

          const today = new Date();

          if (today >= agreementStartDate && today <= agreementEndDate) {
            this.startAgreement = true;
            this.endAgreement = false;
          }

          if (today > agreementStartDate && today > agreementEndDate) {
            if (today > extension) {
              this.startAgreement = false;
              this.endAgreement = true;
            } else {
              this.startAgreement = true;
              this.endAgreement = false;
            }
          }

          if (today < agreementStartDate && today < agreementEndDate) {
            this.startAgreement = false;
            this.endAgreement = false;
          }

          const apprArray = [];

          appraisal.map((appraisalCycles) => {
            const startDate = `${appraisalCycles.cycle_start_date}`;

            const endDate = `${appraisalCycles.cycle_end_date}`;

            apprArray.push({
              periodStart: startDate,
              periodEnd: endDate,
            });

            const hasProperty = this.PerfHeader.some(
              (header) => header.title === "Status"
            );

            appraisalCycles.cycles.map((cycle) => {
              if (!hasProperty) {
                this.PerfHeader.push({
                  title: cycle.name,
                  value: cycle.name,
                });

                apprArray.push({
                  [cycle.name]: `${this.formatDateTime(
                    cycle.appraisal_starts
                  )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
                });
              }
              return {};
            });

            if (!hasProperty) {
              this.appraisalsArr.push(
                apprArray.reduce((r, i) => Object.assign(r, i), {})
              );

              this.PerfHeader.push(
                { title: "Status", value: "status" },
                { title: "", value: "id", image: true }
              );
            }
            return {};
          });

          this.getAgreements();
        } else {
          this.loading = false;
        }
      });
    },

    getPerformanceSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        response.data.PerformanceSettings.map((setting) => {
          const agreementEdit = setting.editDocument;
          this.getUser(agreementEdit);
          return {};
        });
      });
    },

    getUserReportingTo() {
      const payload = { id: this.$AuthUser.id };

      this.$_getOneEmployeeAll(payload).then((response) => {
        this.reportingTo = !!response.data.userReportingTo;
      });
    },

    getUser(agreementEdit) {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((result) => {
        this.loadingData = false;
        if (result.data.directReport.length === 0) {
          this.manager = false;
        } else {
          this.manager = true;
        }

        if (agreementEdit === "manager" && this.manager) {
          this.createAgreement = true;
        } else if (agreementEdit === "manager" && !this.manager) {
          this.createAgreement = false;
        } else if (
          agreementEdit === "employee" &&
          this.manager &&
          !this.reportingTo
        ) {
          this.createAgreement = false;
        } else if (
          agreementEdit === "employee" &&
          this.manager &&
          this.reportingTo
        ) {
          this.createAgreement = true;
        } else if (agreementEdit === "employee" && !this.manager) {
          this.createAgreement = true;
        } else if (agreementEdit === "both") {
          this.createAgreement = true;
        }
      });
    },

    getGoals() {
      this.$_getOrganisationGoal(`?filter=agreement${this.searchQuery}`).then(
        (response) => {
          const goalData = response.data.goals;
          this.orgGoals = goalData;
        }
      );
    },
  },

  mounted() {
    this.getGoals();
    this.getTemplate();
    this.getPerformanceSettings();
    this.getUserReportingTo();
  },
};
</script>
